import React, { useState, useEffect } from "react";
import { api, getBranches, getRefDoctors, getRefLabs } from "../../Api";
import Divider from "../../ui/Divider";
import Loader from "../../ui/Loader";
import moment from "moment";
import Select from "../../ui/Select";
import { Button } from "../../ui/Buttons";
import handleprint from "../../Components/Print/dailySummarExcel";
import { handlePdfPrint } from "../../Components/Print/pdfprint";

const headcells = [
  { label: "Sno" },
  {
    label: "Branch ID",
    valueGetter: (data: Order) => data.branchId,
    colspan: 1,
  },
  {
    label: "Date",
    valueGetter: (data: Order) => moment(data.orderDate).format("DD/MM/YYYY"),
  },
  {
    label: "Patient ID",
    valueGetter: (data: Order) => data.patientId,
    colspan: 1,
  },
  {
    label: "Patient Name",
    valueGetter: (data: Order) =>
      data.patient.nameprefix + " " + data.patient.name,
    colspan: 1,
  },
  {
    label: "Ref. Lab / Doctor",
    valueGetter: (data: Order) =>
      data.lab?.diagonsticname || data.doctor?.doctorname,
    colspan: 1,
  },
  { label: "TestId", valueGetter: (data: Order) => data.id },
  {
    label: "Total Amount",
    valueGetter: (data: Order) => data.totalamount,
    colspan: 1,
  },
  {
    label: "Discount",
    valueGetter: (data: Order) => data.discount,
    colspan: 1,
  },
  {
    label: "Net Amount",
    valueGetter: (data: Order) => data.netamount,
    colspan: 1,
  },
  {
    label: "Amount Paid",
    valueGetter: (data: Order) => data.paidamount,
    colspan: 1,
  },
  {
    label: "Balance Amount",
    valueGetter: (data: Order) => data.balanceamount,
    colspan: 1,
  },
  { label: "Remarks", valueGetter: (data: Order) => data.remarks || "-" },
  {
    label: "Payment Mode",
    valueGetter: (data: Order) => data.paymentmethod,
    colspan: 1,
  },
];

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  value: string;
  handleChange: (e: any) => void;
  divClassname?: string;
}

const FormInput = ({
  label,
  divClassname,
  value,
  handleChange,
  ...rest
}: Props) => {
  return (
    <div className={divClassname}>
      <label className="text-sm font-[600] text-gray-700 mx-2">{label}</label>
      <div className="relative  flex items-center w-full  rounded-sm focus-within:shadow-lg border-2 border-gray-500 focus-within:border-gray-500 bg-white overflow-hidden ml-[10px] px-2 h-9">
        <input
          {...rest}
          onChange={(e) => handleChange(e.target.value)}
          value={value}
          className=" w-full outline-none text-sm text-gray-700 pr-2 border-gray-700"
        />
      </div>
    </div>
  );
};

const currentDate = new Date();
const day = currentDate.getDate();
const month = currentDate.getMonth() + 1;
const year = currentDate.getFullYear();

const formattedDay = day.toString().padStart(2, "0");
const formattedMonth = month.toString().padStart(2, "0");

const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

export default function DailySummary() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [fromDate, setFromDate] = useState(formattedDate);
  const [toDate, setToDate] = useState(formattedDate);
  const [patientname, setPatientname] = useState("");
  const [testcode, setTestcode] = useState("");
  const [testname, setTestname] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [refdoctor, setRefdoctor] = useState("");
  const [reflab, setReflab] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentmethod, setPaymentmethod] = useState("");
  const [reflabs, setReflabs] = useState<RefLab[]>([]);
  const [refdoctors, setRefdoctors] = useState<RefDoctor[]>([]);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [totals, setTotals] = useState<{
    totalamount: number;
    discount: number;
    netamount: number;
    paidamount: number;
    balanceamount: number;
  }>({
    totalamount: 0,
    discount: 0,
    netamount: 0,
    paidamount: 0,
    balanceamount: 0,
  });

  const fetchBranches = async () => {
    const user = JSON.parse(localStorage.getItem("user") || "");
    const data = await getBranches(user.id);
    setBranches(data);
  };

  const fetchRefLabs = async () => {
    setLoading(true);
    const data = await getRefLabs();
    setReflabs(data);
  };

  const fetchRefDoctors = async () => {
    const data = await getRefDoctors();
    setRefdoctors(data);
  };

  const fetchOrders = async () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const branch = JSON.parse(localStorage.getItem("branch") || "{}");
    setLoading(true);
    const res = await api.get(
      "/order/dailySummary?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&patientname=" +
        patientname +
        "&testcode=" +
        testcode +
        "&testname=" +
        testname +
        "&mobilenumber=" +
        mobilenumber +
        "&refdoctor=" +
        refdoctor +
        "&reflab=" +
        reflab +
        "&paymentmethod=" +
        paymentmethod +
        "&allbranches=" +
        user.allbranches +
        "&branchIds=" +
        branch
    );
    setOrders(res.data.data || []);
    const t = {
      totalamount: 0,
      discount: 0,
      netamount: 0,
      paidamount: 0,
      balanceamount: 0,
    };

    res.data.data.forEach((order: Order) => {
      t.totalamount += order.totalamount;
      if (order.discountType === "Percentage")
        t.discount += (order.totalamount * order.discount) / 100;
      else t.discount += order.discount;
      t.netamount += order.netamount;
      t.paidamount += order.paidamount;
      t.balanceamount += order.balanceamount;
    });
    setTotals(t);
    setLoading(false);
  };
  useEffect(() => {
    fetchOrders();
    fetchRefLabs();
    fetchRefDoctors();
    fetchBranches();
  }, []);

  return (
    <div className="flex flex-col">
      <p className="text-right mx-5 my-3 font-medium text-xs">
        Reports {" > "} Daily Summary
      </p>
      <div className="bg-white mx-6 p-6 rounded-md ">
        <div className="grid lg:grid-cols-12 gap-x-10 md:grid-cols-8 sm:grid-cols-4 xs:grid-cols-2 gap-y-6">
          <FormInput
            label="From Date"
            type="date"
            value={fromDate}
            handleChange={(e) => setFromDate(e)}
            placeholder="From Date"
            divClassname="col-span-2"
          />
          <FormInput
            label="To Date"
            type="date"
            value={toDate}
            handleChange={(e) => setToDate(e)}
            placeholder="To Date"
            divClassname="col-span-2"
          />
          <FormInput
            label="Patient Name"
            type="text"
            value={patientname}
            handleChange={(e) => setPatientname(e)}
            placeholder="Patient Name"
            divClassname="col-span-2"
          />
          <FormInput
            label="Test Code"
            type="text"
            value={testcode}
            handleChange={(e) => setTestcode(e)}
            placeholder="Test Code"
            divClassname="col-span-2"
          />
          <FormInput
            label="Test Name"
            type="text"
            value={testname}
            handleChange={(e) => setTestname(e)}
            placeholder="Test Name"
            divClassname="col-span-2"
          />
          <FormInput
            label="Mobile Number"
            type="text"
            value={mobilenumber}
            handleChange={(e) => setMobilenumber(e)}
            placeholder="Mobile Number"
            divClassname="col-span-2"
          />
          <Select
            label="Ref Doctor"
            value={refdoctor}
            onChange={(e) => setRefdoctor(e.target.value)}
            placeholder="Select Ref Doctor"
            divClassName="col-span-2"
            options={refdoctors.map((d) => ({
              value: d.doctorname,
              label: d.doctorname,
            }))}
          />
          <Select
            label="Ref Lab"
            value={reflab}
            onChange={(e) => setReflab(e.target.value)}
            placeholder="Select Ref Lab"
            divClassName="col-span-2"
            options={reflabs.map((r) => ({
              value: r.diagonsticname,
              label: r.diagonsticname,
            }))}
          />
          <Select
            label="Payment Method"
            value={paymentmethod}
            // handleChange={(e) => setStatus(e)}
            options={[
              { value: "Cash", label: "Cash" },
              { value: "UPI", label: "UPI" },
              { value: "Credit", label: "Credit" },
              { value: "Credit Card", label: "Credit Card" },
              { value: "Debit Card", label: "Debit Card" },
            ]}
            placeholder="Select Payment Method"
            onChange={(e) => setPaymentmethod(e.target.value)}
            divClassName="col-span-2"
          />

          <button
            onClick={() => fetchOrders()}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 text-sm rounded h-10 self-end"
          >
            Search
          </button>
        </div>
        <Divider />
        <div className="flex flex-row-reverse gap-3 m-3">
          <div
            className="p-2 rounded-full hover:bg-gray-200 mx-2 cursor-pointer"
            onClick={() =>
              handlePdfPrint({
                rows: orders,
                headcells: headcells,
                fromDate,
                toDate,
                branches,
              })
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
              />
            </svg>
          </div>
          <Button
            onClick={() =>
              handleprint({
                rows: orders,
                fromDate,
                toDate,
                headcells,
                branches,
              })
            }
            label="Print"
          />
        </div>
        {loading ? (
          <div className="flex items-center justify-center h-[5rem]">
            <Loader />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="overflow-hidden border rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr className="bg-[#e0e0e0] p-3 h-[4rem] pl-6">
                      {headcells.map((cell, index) => (
                        <th
                          key={index}
                          scope="col"
                          align="center"
                          className="p-3 text-[13px] font-bold  text-gray-700 uppercase "
                        >
                          {cell.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {orders.length > 0 ? (
                    <tbody className="divide-y divide-gray-200 min-h-[20rem] z-[-1]">
                      {orders.map((order, index) => {
                        return (
                          <tr key={index}>
                            {headcells.map((cell, i) => (
                              <td
                                align="center"
                                className="px-3 py-4 text-sm text-gray-800 whitespace-nowrap"
                              >
                                {cell.valueGetter
                                  ? cell.valueGetter(order)
                                  : index + 1}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={6}></td>
                        <td
                          align="center"
                          className="px-3 py-4 text-sm text-gray-800 whitespace-nowrap"
                        >
                          Grand Total
                        </td>
                        <td
                          align="center"
                          className="px-3 py-4 text-sm text-gray-800 whitespace-nowrap"
                        >
                          {totals.totalamount}
                        </td>
                        <td
                          align="center"
                          className="px-3 py-4 text-sm text-gray-800 whitespace-nowrap"
                        >
                          {totals.discount}
                        </td>
                        <td
                          align="center"
                          className="px-3 py-4 text-sm text-gray-800 whitespace-nowrap"
                        >
                          {totals.netamount}
                        </td>
                        <td
                          align="center"
                          className="px-3 py-4 text-sm text-gray-800 whitespace-nowrap"
                        >
                          {totals.paidamount}
                        </td>
                        <td
                          align="center"
                          className="px-3 py-4 text-sm text-gray-800 whitespace-nowrap"
                        >
                          {totals.balanceamount}
                        </td>
                      </tr>
                      <tr className="h-[10rem]"></tr>
                    </tbody>
                  ) : (
                    <tbody className="divide-y divide-gray-200">
                      <tr>
                        <td
                          colSpan={5}
                          align="left"
                          className="px-5 py-4 text-sm font-semibold  text-gray-800 whitespace-nowrap"
                        >
                          No Orders to show
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
