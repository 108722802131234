import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as z from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Formik } from "formik";
import FormInput from "../FormikComponents/FormInput";
import FormSelect from "../FormikComponents/FormSelect";
import { api, getBranches } from "../../Api";
import BranchAutocomplete from "./Autocomplete";
import Loader from "../../ui/Loader";

const FormSchema = z
  .object({
    name: z.string().min(3, "Name must contain atleast 3 characters").max(50),
    role: z.string(),
    password: z.string().min(6, "Password must be at least 6 characters long"),
    confirmPassword: z.string(),
    allbranches: z.boolean(),
    phonenumber: z
      .number()
      .min(1000000000, "Phone Number must be 10 digits")
      .max(9999999999, "Phone Number must be 10 digits"),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        message: "The passwords did not match",
        path: ["confirmPassword"],
      });
    }
  });
// .refine((data) => data.password === data.confirmPassword, {
//   message: "Passwords don't match",
//   path: ["confirmPassword"],
// });

function AddUser() {
  const [roles, setRoles] = useState<any[]>([]);
  const navigate = useNavigate();
  const [branches, setBranches] = useState<any>([]);
  const [selectedBranches, setSelectedBranches] = useState<any>([]);
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const fetchUser = async () => {
    setLoading(true);
    const data = await api.get("/user/getUser/" + id);
    setUser(data.data.data);
    setSelectedBranches(data.data.data.branches || []);
    setLoading(false);
  };

  const fetchRoles = async () => {
    const res = await api.get("/role/getRoles");
    setRoles(res.data.data || []);
  };

  useEffect(() => {
    if (selectedBranches.length > 0) setError("");
  }, [selectedBranches]);

  const fetchBranches = async () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const data = await getBranches(user.id);
    setBranches(data || []);
  };

  useEffect(() => {
    fetchRoles();
    fetchBranches();
    if (id !== "add") fetchUser();
  }, []);

  const initialValues = {
    name: user?.username || "",
    role: user?.roleId.toString() || "",
    phonenumber: Number(user?.phonenumber) || "",
    password: user?.password || "",
    confirmPassword: user?.password || "",
    allbranches: user?.allbranches || false,
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="p-5 w-full ">
      <div className=" py-5 px-8 rounded-md h-full w-full bg-white shadow-md">
        <p className="text-lg m-2">Add User</p>
        <div className=" border-[1px] border-t border-gray-500 w-full my-3"></div>

        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(FormSchema)}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            if (selectedBranches.length === 0 && !values.allbranches)
              return setError("Please select atleast one branch");

            const body = {
              username: values.name,
              phonenumber: values.phonenumber.toString(),
              password: values.password,
              roleId: parseInt(values.role),
              rolename: roles.find((role) => role.id === parseInt(values.role))
                .name,
              allbranches: values.allbranches,
              branches: selectedBranches,
            };
            try {
              if (user)
                await api.put("/user/edit", {
                  id: user.id,
                  ...body,
                });
              else {
                await api.post("/user/add", body);
              }
              navigate("/users");
            } catch (err) {
              console.log(err);
            }
            // await api
            //   .post("/user/add", {
            //     username: values.name,
            //     phonenumber: values.phonenumber.toString(),
            //     password: values.password,
            //     roleId: parseInt(values.role),
            //     rolename: roles.find(
            //       (role) => role.id === parseInt(values.role)
            //     ).name,
            //   })
            //   .then((res) => {
            //     if (res.status === 200) {
            //       navigate("/users");
            //     }
            //   })
            //   .catch((err) => console.log(err));
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
            console.log(values);

            return (
              <form onSubmit={handleSubmit} className="px-2">
                <div className="grid lg:grid-cols-4  md:grid-cols-3 xs: grid-cols-2 gap-x-5">
                  <FormInput
                    name="name"
                    label="Name"
                    placeholder="Name"
                    className="!w-[100%] !min-w-0"
                    classname="col-span-1"
                    required
                  />
                  <FormSelect
                    name="role"
                    label="Role"
                    options={roles.map((role) => {
                      return { value: role.id, label: role.name };
                    })}
                    placeholder="Select a Role"
                    required
                  />
                  <FormInput
                    name="phonenumber"
                    label="Phone Number"
                    placeholder="Please Enter the phone number"
                    type="number"
                    required
                  />
                  <FormInput
                    name="password"
                    label="Password"
                    placeholder="Password"
                    classname="max-w-[230px]"
                    required
                  />
                  <FormInput
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    required
                  />

                  <BranchAutocomplete
                    branches={branches}
                    setBranches={setSelectedBranches}
                    selectedBranches={selectedBranches}
                    label="Select Branches"
                  />
                </div>
                <div className="flex mx-4 mb-3 items-center">
                  <input
                    className="w-4 h-4 cursor-pointer"
                    type="checkbox"
                    name="allbranches"
                    id="allbranches"
                    onChange={(e) =>
                      setFieldValue("allbranches", e.target.checked)
                    }
                    checked={values.allbranches}
                  />
                  <label htmlFor="allbranches" className="ml-2">
                    All Branches
                  </label>
                </div>
                <div className="flex flex-wrap">
                  {selectedBranches.map((branch: any) => (
                    <div className="mx-4 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                      <span className="mr-3">{branch.name}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 cursor-pointer"
                        onClick={() =>
                          setSelectedBranches(
                            selectedBranches.filter(
                              (b: any) => b.id !== branch.id
                            )
                          )
                        }
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  ))}
                </div>
                {error && <p className="text-red-500">{error}</p>}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={` ${
                    isSubmitting
                      ? "bg-gray-200 hover:bg-gray-100 text-gray-500"
                      : "bg-blue-500  hover:bg-blue-700  text-white"
                  } font-bold py-2 px-3 text-sm rounded my-5 mx-4`}
                >
                  Add User{" "}
                  {isSubmitting && (
                    <div
                      className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    ></div>
                  )}
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddUser;
