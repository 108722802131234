import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../ui/Searchbar";
import Table from "../../Components/TablePages/Table";
import { api, getBranches } from "../../Api";
import DeleteModal from "../../ui/DeleteModal";

const createHeadCell = (
  id: string,
  label: string,
  align?: "right" | "left" | "center",
  minWidth?: number
): headcell => {
  return {
    id,
    label,
    align,
    minWidth,
  };
};

const headcells: headcell[] = [
  createHeadCell("id", "branch Id", "left"),
  createHeadCell("name", "Branch Name", "left"),
  createHeadCell("address", "Address", "left"),
];

export default function Branch() {
  const [branches, setBranches] = useState<Branch[]>([]);
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const navigate = useNavigate();

  const handleDelete = async () => {
    await api.delete(`branch/delete/${id}`);
    fetchBranches();
  };

  const fetchBranches = async () => {
    const user = JSON.parse(localStorage.getItem("user") as string);
    const data = await getBranches(user.id);
    setBranches(data);
  };

  const options = [
    {
      label: "Edit",
      onClick: (id?: number) => navigate(`/branch/${id}`),
    },
    {
      label: "Delete",
      onClick: (id?: number) => {
        setOpen(true);
        setId(id as number);
      },
    },
  ];

  useEffect(() => {
    fetchBranches();
  }, []);

  return (
    <div className="flex flex-col">
      <p className="text-right mx-5 my-5 font-medium text-sm">
        Administration {">"} Branch Creation
      </p>
      <div className="bg-white mx-3 p-6 rounded-md ">
        <div className="flex justify-between mb-3">
          <div className="flex">
            <SearchBar
              value={filter}
              setValue={setFilter}
              placeholder="Search Branch Name"
            />
          </div>
          <button
            onClick={() => navigate("/branch/add")}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 text-sm rounded"
          >
            Add Branch
          </button>
        </div>
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <Table
              headcells={headcells}
              rows={branches.filter((branch) =>
                branch.name.toLowerCase().includes(filter.toLowerCase())
              )}
              options={options}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      </div>
      <DeleteModal open={open} setOpen={setOpen} handleDelete={handleDelete} />
    </div>
  );
}
