import { api, url } from "../Api";

interface Props {
  tests: any[];
  totalamount: number;
  paidamount: number;
  discount: number;
  discountType: string;
  reflab: any;
  refdoctor: any;
  patient: any;
  order?: Order;
  paymentmethod: string;
}

export const generatepdf = async ({
  tests,
  totalamount,
  paidamount,
  discount,
  discountType,
  patient,
  reflab,
  refdoctor,
  order,
  paymentmethod,
}: Props) => {
  // console.log(discountType, discount, discount.toString() + discountType === "Percentage" ? "%" : "Rs" , "hjgjgjfghfghfjjhgjhgjhgjg");

  try {
    const response = await api.get("/bill?orderId=" + order?.id);

    const a = document.createElement("a");
    a.href = `${url}/uploadedFiles/reports/bill${order?.id}.pdf`;
    a.target = "_blank";
    a.click();

    return response.data;
  } catch (error) {
    console.error(error);
    // Handle error
  }
};
