import _ from "lodash";
import ExcelJS from "exceljs";

const handleprint = ({
  rows,
  fromDate,
  toDate,
  headcells,
  branches,
}: {
  rows: any;
  fromDate: string;
  toDate: string;
  headcells: {
    label: string;
    valueGetter?: (row: any) => any;
  }[];
  branches: Branch[];
}) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet 1");
  const branch = JSON.parse(localStorage.getItem("branch") || "{}");

  const border = {
    top: { style: "thin", color: { argb: "black" } },
    left: { style: "thin", color: { argb: "black" } },
    bottom: { style: "thin", color: { argb: "black" } },
    right: { style: "thin", color: { argb: "black" } },
  };

  const headingTextRow = worksheet.addRow(["PATIENT REGISTRATION REPORT"]);
  headingTextRow.height = 40;

  worksheet.mergeCells(
    `${headingTextRow.getCell(1).address}:${
      headingTextRow.getCell(headcells.length).address
    }`
  );

  headingTextRow.eachCell((cell: any) => {
    cell.alignment = { horizontal: "center", vertical: "middle" };
    cell.font = { size: 16, bold: true };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };

    cell.border = border;
  });

  //   function createHeading(heading: any) {
  //     const headingTextRow = worksheet.addRow(heading.header);
  //     headingTextRow.height = heading.height || 40;

  //     worksheet.mergeCells(
  //       `${headingTextRow.getCell(1).address}:${
  //         headingTextRow.getCell(headcells.length).address
  //       }`
  //     );

  //     headingTextRow.eachCell((cell: any) => {
  //       cell.alignment = { horizontal: "center", vertical: "middle" };
  //       cell.font = heading.font;
  //       cell.fill = {
  //         type: "pattern",
  //         pattern: "solid",
  //         fgColor: { argb: "ffffff" },
  //       };

  //       cell.border = border;
  //     });
  //   }

  //   headings.forEach((heading) => {
  //     createHeading(heading);
  //   });

  //   createHeading({
  //     header: [` Branch: ${branch}   From: ${fromDate} To: ${toDate}`],
  //     height: 30,
  //     font: { size: 12, bold: true },
  //   });

  const headingTextRow1 = worksheet.addRow([
    "Branch:",
    branch.length > 0 ? "ALL" : branches.find((b) => b.id === branch[0])?.name,
    "",
    "From:",
    fromDate,
    "",
    "To:",
    toDate,
    "",
  ]);

  headingTextRow1.height = 30;

  worksheet.mergeCells(
    `${headingTextRow1.getCell(9).address}:${
      headingTextRow1.getCell(headcells.length).address
    }`
  );

  headingTextRow1.eachCell((cell: any, index: number) => {
    cell.alignment = { horizontal: "center", vertical: "middle" };
    cell.font = { size: 10, bold: index === 1 || index === 4 || index === 7 }; // Set bold for even-indexed cells
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };

    // Apply border to the end column
    if (index === headingTextRow1.cellCount) {
      cell.border = border;
    }
  });

  const tableheader = worksheet.addRow(headcells.map((h) => h.label));

  // Set column widths based on content
  tableheader.eachCell((cell: any, index: number) => {
    const column = worksheet.getColumn(index + 1);
    column.width = Math.max(
      15,
      (_.max(
        ((rows as any[]) || []).map(
          (row: any) => String(row[index] ?? "").length
        )
      ) || 0) * 1.2
    );
  });

  tableheader.eachCell((cell: any) => {
    cell.alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: "center",
    };
    cell.font = { bold: true, size: 11, wrapText: true };
    cell.border = border;
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "e0e0e0" }, // Replace 'FFFF0000' with the desired color code
    };
  });

  rows.forEach((row: any) => {
    const data = headcells.map((h, index) =>
      h.valueGetter ? h.valueGetter(row) : index + 1
    );
    const datarow = worksheet.addRow(data);
    datarow.eachCell((cell: any, index: number) => {
      cell.alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center",
        innerWidth: 10,
      };
      cell.border = border;
      cell.font = { size: 9, wrapText: false };
    });

    // Adjust row height based on content
    datarow.eachCell((cell: any, index: number) => {
      const row = worksheet.getRow(datarow.number);
      const height = Math.max(
        36,
        Math.ceil(cell.value.toString().length / 50) * 15
      );
      row.height = Math.max(row.height, height);
    });
  });

  workbook.xlsx.writeBuffer().then((buffer: any) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Patient Registration Report.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

export default handleprint;
