import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { accessToken, api, deleteFile, instanceid, url } from "../../Api";
import Loader from "../../ui/Loader";
import { Button } from "../../ui/Buttons";
import axios from "axios";

interface props {
  isOpen: boolean;
  closeModal: () => void;
  order: Order;
  patient: Patient | undefined;
}

interface selected {
  type: "test" | "profile" | "package";
  name: string;
}

export default function PreviewModal({
  closeModal,
  isOpen,
  order,
  patient,
}: props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTests, setSelectedTests] = useState<selected[]>([]);
  const [printSetting, setPrintSetting] = useState<
    | (PrintSetting & {
        signs: Signs[];
      })
    | null
  >(null);
  const [letterhead, setLetterhead] = useState<boolean>(
    !printSetting?.uploadletterhead || false
  );
  const [loading1, setLoading1] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [mobile, setMobile] = useState(patient?.phonenumber);

  const fetchPrintSetting = async () => {
    const res = await api.get(
      "/setting/getSettings?branchId=" + order?.branchId
    );
    if (res.data.settings) setPrintSetting(res.data.settings);
  };
  const [order1, setOrder] = useState<Order | null>(null);
  const [message, setMessage] = useState<string>("");

  const fetchPDF = async (selected?: selected[]) => {
    try {
      setLoading(true);
      setCount(count + 1);
      await api.post(
        "/generatereport",
        {
          patientId: patient?.id,
          orderId: order.id,
          selected: selected || selectedTests,
          letterhead,
        },
        {
          responseType: "blob",
        }
      );
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const handleSendReport = async () => {

    setLoading1(true);
    try {
      await axios.get(
        `https://whatsapp.labauto.in/api/send?number=91${mobile}&type=media&message=Hi+*${
          patient?.nameprefix + " " + patient?.name
        }*,+Your+recent+health+test+results+are+ready!+Please+find+the+attached+PDF+report.
        %0a%0aIf+you+need+any+clarification+or+follow-up,+our+team+is+here+to+help.
        &media_url=${url}/uploadedFiles/reports/Report${
          order.id
        }.pdf&instance_id=6697B2AE0F9A4&access_token=66979e95d92b0`
      );
    } catch (error) {
      console.error(error);
    }
    setLoading1(false);
    setMessage("Report sent successfully");
    // setTimeout(() => {
    //   setMessage("");
    // }, 2000);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);

  const fetchOrder = async () => {
    const res = await api.get("/order/getOrder/" + order.id);
    setOrder(res.data.data);
  };

  useEffect(() => {
    // handleclickall();
    // fetchPDF();
    // fetchDepartments();

    if (order1) {
      if (count === 0) {
        handleclickall();
      }
      fetchPrintSetting();
    }
  }, [order1]);

  useEffect(() => {
    if (isOpen && order && patient) fetchOrder();
  }, []);

  const getCount = (p: OrderPackage[]) => {
    let count = 0;
    p.forEach((pkg) => {
      pkg.tests.forEach((test) => {
        if (test.observedValue && !test.optional) count++;
      });
      pkg.profiles.forEach((profile) => {
        let flag = true;
        profile.headings.forEach((heading) => {
          heading.tests.forEach((test) => {
            if (!test.observedValue && !test.optional) flag = false;
          });
        });
        if (flag) count++;
      });
    });

    return count;
  };

  const getValidity2 = (pkg: OrderProfile) => {
    pkg.headings.forEach((heading) => {
      heading.tests.forEach((test) => {
        
        if (!test.observedValue && !test.optional) {
          console.log("test", test, !test.optional);
          
          return false;
        }
      });
    });

    return true;
  };

  const handleChange = (e: any, { type, name }: selected) => {
    if (e.target.checked) setSelectedTests([...selectedTests, { type, name }]);
    else {
      setSelectedTests(
        selectedTests.filter((s) => !(s.name === name && s.type === type))
      );
    }
  };

  const handleclickall = (e?: any) => {
    if (e && !e.target.checked) {
      setSelectedTests([]);
      return;
    }
    let selected: selected[] = [];
    order1?.packages?.forEach((pkg) => {
      pkg.tests.forEach((test) => {
        if (test.observedValue)
          selected.push({ type: "test", name: test.name });
      });
      pkg.profiles.forEach((profile) => {
        let flag = true;
        profile.headings.forEach((heading) => {
          heading.tests.forEach((test) => {
            if (!test.observedValue && !test.optional) flag = false;
          });
        });
        if (flag) selected.push({ type: "profile", name: profile.name });
      });
      // if (flag) selected.push({ type: "package", name: pkg.name });
    });
    order1?.profiles.forEach((profile) => {
      let flag = false;
      if (profile.tests.find((test) => test.observedValue))
        selected.push({ type: "profile", name: profile.name });
      profile.headings.forEach((heading) => {
        heading.tests.forEach((test) => {
          if (!test.observedValue && !test.optional) flag = true;
        });
      });
      if (!flag) selected.push({ type: "profile", name: profile.name });
    });
    order1?.tests.forEach((test) => {
      if (test.observedValue) selected.push({ type: "test", name: test.name });
    });
    setSelectedTests(selected);
    fetchPDF(selected);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-5" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-opacity-70 bg-black">
            <div className="flex min-h-full items-center justify-center  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {order1 ? (
                  <Dialog.Panel className="w-full max-w-[75%] transform overflow-auto rounded-md bg-white  text-left align-middle shadow-xl transition-all h-[100vh]">
                    <div className="flex h-full">
                      <div
                        className="flex items-center cursor-pointer absolute top-4 right-4 z-50"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div
                        className="flex flex-col overflow-y-auto w-full"
                        // style={{ height: "90%" }}
                      >
                        {loading ? (
                          <div className="flex items-center justify-center h-full">
                            <Loader />
                          </div>
                        ) : (
                          <iframe
                            src={`${url}/uploadedFiles/reports/Report${order.id}.pdf`}
                            width="100%"
                            height="100%"
                            style={{ border: "none" }}
                            name="Name"
                            title="Title"
                          ></iframe>
                        )}
                      </div>
                      <div className="w-[40%] p-6">
                        <div className="mb-8 mt-4 text-sm ">
                          <p className="my-1 text-gray-600">Patient Details</p>
                          <h4 className="font-semibold">
                            {(patient?.nameprefix || "") +
                              (patient?.name || "")}
                          </h4>
                          <p>
                            ( {patient?.age + " " + (patient?.agesuffix || "")}{" "}
                            / {patient?.gender} )
                          </p>
                        </div>

                        <div className="my-4 text-sm">
                          <p className="my-2 text-gray-600 ">
                            Letter Head Settings
                          </p>
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                              checked={letterhead}
                              onChange={() => setLetterhead(!letterhead)}
                            />
                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none  peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                            <span className="font-semibold mx-3">
                              Letter Head
                            </span>
                          </label>
                        </div>

                        <div className="w-[100%] max-h-[200px] overflow-y-auto flex flex-col text-sm  text-gray-950 my-12">
                          <p className="my-2 text-gray-600">
                            Select the tests you want to print
                          </p>
                          <ul className="flex flex-col gap-2">
                            <li className="flex gap-2 items-center">
                              <input
                                onChange={handleclickall}
                                type="checkbox"
                                name="kkk"
                                id="kkk"
                                checked={
                                  selectedTests.length ===
                                  getCount(order1.packages) +
                                    order1.profiles.length +
                                    order1.tests.length
                                }
                              />
                              <label htmlFor="kkk">Select all</label>
                            </li>
                            {order1 &&
                              order1?.packages?.map((pkg) => (
                                <>
                                  {pkg.profiles.map((profile) => {
                                    if(!getValidity2(profile)) console.log("testing");
                                    
                                    return (
                                      <li
                                      className="flex gap-2 items-center"
                                      key={profile.id}
                                    >
                                      <input
                                        checked={
                                          !!selectedTests.find(
                                            (s) => s.name === profile.name
                                          )
                                        }
                                        onChange={(e) =>
                                          handleChange(e, {
                                            type: "profile",
                                            name: profile.name,
                                          })
                                        }
                                        className={`border-2 border-gray-700 ${
                                          !getValidity2(profile) &&
                                          "bg-gray-700"
                                        }`}
                                        type="checkbox"
                                        name=""
                                        id=""
                                      />
                                      <label
                                        className={`${
                                          getValidity2(profile) &&
                                          "font-semibold"
                                        }`}
                                        htmlFor=""
                                      >
                                        {profile.name}
                                      </label>
                                    </li>
                                    )
                                  })}
                                  {pkg?.tests?.map((test) => (
                                    <li
                                      className="flex gap-2 items-center"
                                      key={test.id}
                                    >
                                      <input
                                        onChange={(e) =>
                                          handleChange(e, {
                                            type: "test",
                                            name: test.name,
                                          })
                                        }
                                        checked={
                                          !!selectedTests.find(
                                            (s) =>
                                              s.type === "test" &&
                                              s.name === test.name
                                          )
                                        }
                                        className={`border-2 border-gray-700 ${
                                          !test.observedValue && "bg-gray-700"
                                        }`}
                                        type="checkbox"
                                        name=""
                                        id={test.name + "test"}
                                        disabled={!test.observedValue}
                                      />
                                      <label
                                        className={`${
                                          test.observedValue && "font-semibold"
                                        }`}
                                        defaultChecked={!!test.observedValue}
                                        htmlFor={test.name + "test"}
                                      >
                                        {test.name}
                                      </label>
                                    </li>
                                  ))}
                                </>
                              ))}

                            {order1 &&
                              order1?.profiles?.map((profile) => (
                                <li
                                  className="flex gap-2 items-center"
                                  key={profile.id}
                                >
                                  <input
                                    checked={
                                      !!selectedTests.find(
                                        (s) => s.name === profile.name
                                      )
                                    }
                                    onChange={(e) =>
                                      handleChange(e, {
                                        type: "profile",
                                        name: profile.name,
                                      })
                                    }
                                    className={`border-2 border-gray-700 ${
                                      !getValidity2(profile) && "bg-gray-700"
                                    }`}
                                    type="checkbox"
                                    name=""
                                    id=""
                                  />
                                  <label
                                    className={`${
                                      getValidity2(profile) && "font-semibold"
                                    }`}
                                    htmlFor=""
                                  >
                                    {profile.name}
                                  </label>
                                </li>
                              ))}

                            {order1?.tests?.map((test) => (
                              <li
                                className="flex gap-2 items-center"
                                key={test.id}
                              >
                                <input
                                  onChange={(e) =>
                                    handleChange(e, {
                                      type: "test",
                                      name: test.name,
                                    })
                                  }
                                  checked={
                                    !!selectedTests.find(
                                      (s) =>
                                        s.type === "test" &&
                                        s.name === test.name
                                    )
                                  }
                                  className={`border-2 border-gray-700 ${
                                    !test.observedValue && "bg-gray-700"
                                  }`}
                                  type="checkbox"
                                  name=""
                                  id={test.name + "test"}
                                  disabled={!test.observedValue}
                                />
                                <label
                                  className={`${
                                    test.observedValue && "font-semibold"
                                  }`}
                                  defaultChecked={!!test.observedValue}
                                  htmlFor={test.name + "test"}
                                >
                                  {test.name}
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>

                        {(printSetting?.whatsappSetting === "automatic" ||
                          printSetting?.whatsappSetting === "manual") && (
                          <div className="text-sm">
                            <p className="my-2 text-gray-600 ">
                              Quick Share Options
                            </p>
                            <div className="flex gap-2 items-end">
                              <img
                                src="/whatsapp.png"
                                alt="whatsapp"
                                className="w-8 h-8"
                              />
                              <input
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                type="number"
                                className="w-2/3 border-b-2 outline-none  border-gray-600  p-2"
                              />
                              <Button
                                label="Send"
                                className="bg-green-600 hover:bg-green-700"
                                onClick={handleSendReport}
                                loading={loading1}
                              />
                            </div>
                            {message && (
                              <p className="text-green-600 mt-3">{message}</p>
                            )}
                          </div>
                        )}

                        <div className="absolute  bottom-0 right-0 flex gap-4 items-center p-4">
                          <Button
                            label="Print"
                            onClick={() => fetchPDF(selectedTests)}
                          />
                          <Button
                            label="Cancel"
                            variant="outlined"
                            onClick={() => {
                              closeModal();
                              setSelectedTests([]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                ) : (
                  <Dialog.Panel className="w-full max-w-[75%] transform overflow-auto rounded-md bg-white  text-left align-middle shadow-xl transition-all h-[100vh]">
                    <Loader />
                  </Dialog.Panel>
                )}
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
