import AppWidgetSummary from "../Components/Dashboard/AppWidget";
import ChartFour from "../Components/Charts/ChartFour";
import ChartOne from "../Components/Charts/ChartOne";
import ChartTwo from "../Components/Charts/ChartTwo";
import ChartThree from "../Components/Charts/ChartThree";

export default function Home() {

  return (
    <div className="bg-gray-50 p-10 px-20 min-h-screen">
      <div className="flex gap-3 justify-between mb-10">
        <AppWidgetSummary
          title="Total Sales"
          total={545}
          icon={<img alt="icon" src="/ic_glass_bag.png" />}
        />
        <AppWidgetSummary
          title="Total Users"
          total={137}
          icon={<img alt="icon" src="/ic_glass_users.png" />}
        />
        <AppWidgetSummary
          title="Total Orders"
          total={678}
          icon={<img alt="icon" src="/ic_glass_buy.png" />}
        />
        <AppWidgetSummary
          title="Total Messages"
          total={100}
          icon={<img alt="icon" src="/ic_glass_message.png" />}
        />
        {/* <AppWidgetSummary
          title="Total Messages"
          total={100}
          icon={<img alt="icon" src="/ic_glass_message.png" />}
        /> */}
      </div>
      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <ChartOne />
        <ChartTwo />
        <div className="col-span-12">
          <ChartFour />
        </div>
        <ChartThree />
      </div>

      <div className="mt-4 grid grid-cols-3 gap-4">
        <div className="col-span-2 ">
          {/* <AppWebsiteVisits
            title="Website Visits"
            subheader="(+43%) than last year"
            chart={{
              labels: [
                "01/01/2003",
                "02/01/2003",
                "03/01/2003",
                "04/01/2003",
                "05/01/2003",
                "06/01/2003",
                "07/01/2003",
                "08/01/2003",
                "09/01/2003",
                "10/01/2003",
                "11/01/2003",
              ],
              series: [
                {
                  name: "Team A",
                  type: "column",
                  fill: "solid",
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: "Team B",
                  type: "area",
                  fill: "gradient",
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: "Team C",
                  type: "line",
                  fill: "solid",
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ],
            }}
          /> */}
        </div>
        {/* <AppCurrentVisits
          title="Current Visits"
          chart={{
            series: [
              { label: "America", value: 4344 },
              { label: "Asia", value: 5435 },
              { label: "Europe", value: 1443 },
              { label: "Africa", value: 4443 },
            ],
          }}
        /> */}
      </div>
    </div>
  );
}
