import { jsPDF } from "jspdf";
import "jspdf-autotable";

export const handlePdfPrint = ({
  headcells,
  rows,
  branches,
  fromDate,
  toDate,
}: {
  headcells: {
    label: string;
    valueGetter?: (row: any) => any;
  }[];
  rows: any[];
  branches: Branch[];
  fromDate: string;
  toDate: string;
}) => {
  const branch = JSON.parse(localStorage.getItem("branch") || "{}");

  const doc = new jsPDF();

  doc.text("Patient Registration Report", 10, 10);

  const b =
    branch.length > 1
      ? "ALL"
      : branches.find((b) => b.id === branch[0])?.name || "";

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text("Branch : ", 10, 20);
  doc.setFont("helvetica", "normal");
  doc.text(b, 25, 20);
  doc.setFont("helvetica", "bold");
  doc.text("From Date : ", b.length + 50, 20);
  doc.setFont("helvetica", "normal");
  doc.text("12-Feb-2024 ", b.length + 70, 20);
  doc.setFont("helvetica", "bold");
  doc.text("To Date : ", b.length + 100, 20);
  doc.setFont("helvetica", "normal");
  doc.text("12-Feb-2024", b.length + 120, 20);

  doc.autoTable({
    startY: 30,
    head: [headcells.map((cell) => cell.label)],
    body: rows.map((row, i) =>
      headcells.map((cell) =>
        cell.valueGetter ? cell.valueGetter(row) : i + 1
      )
    ),
    headStyles: { fillColor: "#e0e0e0", textColor: "#000" },
    styles: {
      halign: "center",
      cellPadding: 1,
      fontSize: 7,
    },
  });

  doc.save("Patient Registration Report.pdf");
};
