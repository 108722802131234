import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import FormSelect from "../../ui/FormSelect";

const options: ApexOptions = {
  colors: ["#3C50E0", "#80CAEE"],
  chart: {
    // events: {
    //   beforeMount: (chart) => {
    //     chart.windowResizeHandler();
    //   },
    // },
    fontFamily: "Satoshi, sans-serif",
    type: "bar",
    height: 335,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },

  responsive: [
    {
      breakpoint: 1536,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: "25%",
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: "25%",
      borderRadiusApplication: "end",
      borderRadiusWhenStacked: "last",
    },
  },
  dataLabels: {
    enabled: false,
  },

  xaxis: {
    categories: ["M", "T", "W", "T", "F", "S", "S"],
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    itemMargin: {
      horizontal: 20,
    },
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    fontSize: "14px",

    markers: {
      radius: 99,
    },
  },
  fill: {
    opacity: 1,
  },
};

interface ChartTwoState {
  series: {
    name: string;
    data: number[];
  }[];
}

const ChartTwo: React.FC = () => {
  const [week, setWeek] = useState<string>("this week");
  const [state, setState] = useState<ChartTwoState>({
    series: [
      {
        name: "Sales",
        data: [44, 55, 41, 67, 22, 43, 65],
      },
      {
        name: "Revenue",
        data: [13, 23, 20, 8, 13, 27, 15],
      },
    ],
  });

  const handleReset = () => {
    setState((prevState) => ({
      ...prevState,
    }));
  };

  useEffect(() => {
    handleReset();
  }, []);

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white p-6 shadow-default  xl:col-span-4">
      <div className="mb-4 justify-between gap-4 sm:flex">
        <div>
          <h4 className="text-lg font-semibold text-black ">Profit {week}</h4>
        </div>
        <div>
          <FormSelect
            value={week}
            options={[
              { value: "this week", label: "This Week" },
              { value: "last week", label: "Last Week" },
            ]}
            setValue={(value) => setWeek(value)}
            placeholder="This Week"
            divClassName="!mx-0"
            inputClassName="w-fit min-w-[130px] p-1 h-9 border-none shadow-sm text-sm font-medium outline-none"
          />
        </div>
      </div>

      <div>
        <div id="chartTwo" className="-ml-5 -mb-9">
          <ApexCharts
            options={options}
            series={state.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartTwo;
