import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function BranchAutocomplete({
  setBranches,
  branches,
  selectedBranches,
  label,
  placeholder,
  className,
  comparator = "name",
}: {
  setBranches: React.Dispatch<React.SetStateAction<any[]>>;
  branches: any[];
  selectedBranches: any[];
  label?: string;
  placeholder?: string;
  className?: string;
  comparator?: string;
}) {
  const [query, setQuery] = useState("");

  console.log(branches, "branches", query, "query");

  const filtered =
    query === ""
      ? branches.map((p) => {
          return { id: p.id, name: p[comparator] };
        })
      : branches
          .filter((test) =>
            test[comparator]
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
          )
          .map((p) => {
            return { id: p.id, name: p[comparator] };
          });

  return (
    <div
      className={`flex  items-center my-3 mx-4  ${className} justify-self-center w-full`}
    >
      <div className="w-full mx-4">
        <Combobox
          // value={selected}
          value=""
          onChange={(name) => {
            if (name) {
              if (!selectedBranches.find((i) => i[comparator] === name)) {
                setBranches((t) => [
                  ...t,
                  branches.find((i) => i[comparator] === name),
                ]);
              }
            }
          }}
        >
          <div className="relative mt-0 w-full">
            <p className="text-sm font-semibold text-gray-700 ">{label}</p>
            <div
              className={`relative w-full cursor-default border-2 border-gray-400 focus-within:border-blue-600 overflow-hidden text-left shadow-md  sm:text-sm my-2 ${className}`}
            >
              <Combobox.Input
                className={`  border-none focus:border-none focus-visible:border-none focus-visible:outline-none py-3 pl-4 pr-10 text-md leading-5 h-9 text-gray-900 w-full  ${className}`}
                displayValue={(person: any) => person.name}
                onChange={(event) => setQuery(event.target.value)}
                placeholder={placeholder}
                autoComplete="off"
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto  bg-white  py-1  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                {filtered.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filtered
                    // .filter((f) => f.name. === "dfvddvdv")
                    .map((person) => (
                      <Combobox.Option
                        key={person.name}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 ${
                            active ? "bg-teal-600 text-white" : "text-gray-900"
                          }`
                        }
                        value={person.name}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate cursor-pointer ${
                                selectedBranches.find(
                                  (t) => t[comparator] === person.name
                                )
                                  ? "font-medium"
                                  : "font-normal"
                              }`}
                            >
                              {person.name}
                            </span>
                            {selectedBranches.find(
                              (t) => t[comparator] === person.name
                            ) ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? "text-white" : "text-teal-600"
                                }`}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
}
