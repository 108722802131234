import { useEffect, useRef, useState } from "react";
import FormSelect from "../ui/FormSelect";
import { cn } from "../utils";
import { api, getDepartments, url } from "../Api";
import { Button } from "../ui/Buttons";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import FormInput from "../ui/FormInput";
import DialogBox from "../ui/DialogBox";
import Divider from "../ui/Divider";
import SignsUpload from "../Components/settings";

export default function Setting() {
  const [printSetting, setPrintSetting] = useState<PrintSetting>({
    uploadletterhead: false,
    letterhead: null,
    billbgimage: null,
    departmentwise: false,
    disableqrcode: false,
    endline: "",
    endlineposition: "",
    id: 0,
    pagenumber: false,
    profilenewpage: false,
    showendline: false,
    signdepartmentwise: false,
    testnewpage: false,
    testprofilesamepage: false,
    departmentwisesigns: false,
    topmargin: 0,
    bottommargin: 0,
    leftmargin: 0,
    rightmargin: 0,
    profilefirst: false,
    commonsigns: false,
    whatsappSetting: "",
  });

  const [loading, setLoading] = useState(false);
  const [contentloading, setContentLoading] = useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [open, setOpen] = useState(false);

  const branches = JSON.parse(localStorage.getItem("branch") as string);

  const fetchSettings = async () => {
    if (!branches || branches.length > 1) return;

    setContentLoading(true);
    const res = await api.get("/setting/getSettings?branchId=" + branches[0]);
    if (res.data.settings) setPrintSetting(res.data.settings);
    setContentLoading(false);
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await api.post("/upload", formData, config);
    const data = res.data?.data;
    if (data)
      setPrintSetting({
        ...printSetting,
        uploadletterhead: true,
        letterhead: data[0]?.filename,
      });
  };

  const handleBillUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await api.post("/upload", formData, config);
    const data = res.data?.data;
    if (data)
      setPrintSetting({
        ...printSetting,
        billbgimage: data[0]?.filename,
      });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const { signs, whatsappSetting, ...rest } = printSetting;
      await api.post("/setting/save", {
        settings: {
          ...rest,
          topmargin: Number(rest.topmargin),
          bottommargin: Number(rest.bottommargin),
          leftmargin: Number(rest.leftmargin),
          rightmargin: Number(rest.rightmargin),
          branchId: branches[0],
        },
        departments: departments,
      });
      fetchSettings();
      fetchDepartments();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchDepartments = async () => {
    const res = await getDepartments();
    setDepartments(res);
  };

  useEffect(() => {
    fetchSettings();
    fetchDepartments();
  }, []);

  const dragPerson = useRef<number>(0);
  const draggedOverPerson = useRef<number>(0);

  function handleSort() {
    setDepartments((prev) => {
      return prev.map((department, index) => {
        if (index === dragPerson.current) {
          const d = departments[draggedOverPerson.current];
          return { ...d, order: departments.length - index };
        }
        if (index === draggedOverPerson.current) {
          const d = departments[dragPerson.current];
          return { ...d, order: departments.length - index };
        }
        return { ...department, order: departments.length - index };
      });
    });
  }

  const handleDeleteSign = async (id: number) => {
    try {
      await api.delete("/setting/deleteSign/" + id);
      fetchSettings();
    } catch (error) {
      console.log(error);
    }
  };

  const handlesave2 = async (whatsappSetting: string) => {
    try {
      await api.post("/setting/save", {
        settings: {
          id: printSetting.id,
          whatsappSetting: whatsappSetting,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="px-7 py-4 pb-40">
      <div className=" py-2 px-8 rounded-md h-full w-full bg-white shadow-md">
        <p className="text-lg m-2 font-bold">Print Setting</p>
      </div>

      {branches.length > 1 ? (
        <div className="p-3 px-7 rounded-md h-full w-full bg-white shadow-md mt-3">
          <h3>Please Select a Branch</h3>
        </div>
      ) : (
        !contentloading && (
          <div>
            <div className="p-3 px-7 rounded-md h-full w-full bg-white shadow-md my-3">
              <p className="text-md font-semibold">Lab Default Setting</p>
              <div className="flex flex-col gap-4 mt-4">
                <SettingItem
                  label="Print QR Code"
                  value={printSetting?.disableqrcode || false}
                  handleChange={(value) =>
                    setPrintSetting({ ...printSetting, disableqrcode: value })
                  }
                />
                <SettingItem
                  label="Print Page Number"
                  value={printSetting?.pagenumber || false}
                  handleChange={(value) =>
                    setPrintSetting({ ...printSetting, pagenumber: value })
                  }
                />
                <p className="text-md font-semibold mt-3">
                  Reports Print & Share Default Settings
                </p>
                <SettingItem
                  label="Test New Page"
                  value={printSetting?.testnewpage || false}
                  handleChange={(value) =>
                    setPrintSetting({ ...printSetting, testnewpage: value })
                  }
                />
                <SettingItem
                  label="Profile New Page"
                  value={printSetting?.profilenewpage || false}
                  handleChange={(value) =>
                    setPrintSetting({ ...printSetting, profilenewpage: value })
                  }
                />
                <SettingItem
                  label="Test Profile Same Page"
                  value={printSetting?.testprofilesamepage || false}
                  handleChange={(value) =>
                    setPrintSetting({
                      ...printSetting,
                      testprofilesamepage: value,
                    })
                  }
                />
                <SettingItem
                  label="Print Profile Before Tests"
                  value={printSetting?.profilefirst || false}
                  handleChange={(value) =>
                    setPrintSetting({ ...printSetting, profilefirst: value })
                  }
                />
                <div className="flex justify-between items-center">
                  <p className="text-sm font-semibold">
                    Print End Report Text:
                  </p>
                  <SettingItem
                    label="Show End Report Text"
                    value={printSetting?.showendline || false}
                    handleChange={(value) =>
                      setPrintSetting({ ...printSetting, showendline: value })
                    }
                  />

                  <FormSelect
                    value={printSetting?.endlineposition || ""}
                    setValue={(value) =>
                      setPrintSetting({
                        ...printSetting,
                        endlineposition: value as string,
                      })
                    }
                    placeholder="Select End Line Position"
                    options={[
                      { value: "true", label: "Show in Each Test End" },
                      { value: "false", label: "Show Last of Report" },
                    ]}
                    inputClassName="!border-0 !border-b  rounded-b-none h-9 focus-within:border-b-2"
                    divClassName="border-0"
                  />
                  <input
                    value={printSetting?.endline || ""}
                    onChange={(e) =>
                      setPrintSetting({
                        ...printSetting,
                        endline: e.target.value,
                      })
                    }
                    placeholder="Enter End Line"
                    className=" outline-none border-b border-gray-300 focus-within:border-blue-500 focus-within:border-b-2  focus-within:py-1 text-sm font-semibold transition-all duration-300 ease-in-out px-2 py-2 min-w-[15rem]"
                  />
                </div>
                <div className="flex items-center w-full justify-between">
                  <SettingItem
                    label="Upload Letter Head"
                    value={printSetting?.showendline || false}
                    handleChange={(value) =>
                      setPrintSetting({ ...printSetting, showendline: value })
                    }
                    className="font-semibold"
                  />

                  <label htmlFor="upload" className="cursor-pointer">
                    <span className="w-full py-2 bg-black/5">
                      {printSetting.letterhead ? (
                        <div>
                          <div className="w-30 h-24 shadow-md border border-black/10">
                            <img
                              src={`${url}/uploadedFiles/${printSetting.letterhead}`}
                              alt=""
                              className="h-full w-full"
                            />
                          </div>
                          <p className="text-[11px] text-center w-full py-2 px-2 bg-black/80 border border-black/20 text-white">
                            Upload Letter Head
                          </p>
                        </div>
                      ) : (
                        <p className="px-2 bg-btnBackground text-white py-2 rounded-sm">
                          Upload Letter Head
                        </p>
                      )}
                    </span>
                    <input
                      type="file"
                      id="upload"
                      hidden
                      onChange={handleUpload}
                    />
                  </label>
                  <label htmlFor="upload1" className="cursor-pointer">
                    <span className="w-full py-2 bg-black/5">
                      {printSetting.billbgimage ? (
                        <div>
                          <div className="w-30 h-24 shadow-md border border-black/10">
                            <img
                              src={`${url}/uploadedFiles/${printSetting.billbgimage}`}
                              alt=""
                              className="h-full w-full"
                            />
                          </div>
                          <p className="text-[11px] text-center w-full py-2 px-4 bg-black/80 border border-black/20 text-white">
                            Upload Bill Head
                          </p>
                        </div>
                      ) : (
                        <p className="px-2 bg-btnBackground text-white py-2 rounded-sm">
                          Upload Bill Head
                        </p>
                      )}
                    </span>
                    <input
                      type="file"
                      id="upload1"
                      hidden
                      onChange={handleBillUpload}
                    />
                  </label>
                  <div className="grid grid-cols-2">
                    <FormInput
                      label="Top Margin"
                      value={printSetting?.topmargin || 0}
                      type="number"
                      handleChange={(value) =>
                        setPrintSetting({
                          ...printSetting,
                          topmargin: value as number,
                        })
                      }
                      className="outline-none border-b border-gray-300 focus-within:border-blue-500 focus-within:border-b-2  focus-within:py-1 text-sm font-semibold transition-all duration-300 ease-in-out px-2 py-2 min-w-[10rem]"
                      placeholder="Top Margin"
                    />
                    <FormInput
                      label="Bottom Margin"
                      value={printSetting?.bottommargin || 0}
                      type="number"
                      handleChange={(value) =>
                        setPrintSetting({
                          ...printSetting,
                          bottommargin: value as number,
                        })
                      }
                      className="outline-none border-b border-gray-300 focus-within:border-blue-500 focus-within:border-b-2  focus-within:py-1 text-sm font-semibold transition-all duration-300 ease-in-out px-2 py-2 min-w-[10rem]"
                      placeholder="Bottom Margin"
                    />
                    <FormInput
                      label="Left Margin"
                      value={printSetting?.leftmargin || 0}
                      type="number"
                      handleChange={(value) =>
                        setPrintSetting({
                          ...printSetting,
                          leftmargin: value as number,
                        })
                      }
                      className="outline-none border-b border-gray-300 focus-within:border-blue-500 focus-within:border-b-2  focus-within:py-1 text-sm font-semibold transition-all duration-300 ease-in-out px-2 py-2 min-w-[10rem]"
                      placeholder="Left Margin"
                    />
                    <FormInput
                      label="Right Margin"
                      value={printSetting?.rightmargin || 0}
                      type="number"
                      handleChange={(value) =>
                        setPrintSetting({
                          ...printSetting,
                          rightmargin: value as number,
                        })
                      }
                      className="outline-none border-b border-gray-300 focus-within:border-blue-500 focus-within:border-b-2  focus-within:py-1 text-sm font-semibold transition-all duration-300 ease-in-out px-2 py-2 min-w-[10rem]"
                      placeholder="Right Margin"
                    />
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <SettingItem
                    label="Department Wise Signatures"
                    value={printSetting?.departmentwisesigns || false}
                    handleChange={(value) =>
                      setPrintSetting({
                        ...printSetting,
                        departmentwisesigns: value,
                        commonsigns: !value,
                      })
                    }
                    className="font-semibold"
                  />
                  <p className="text-sm font-semibold">OR</p>
                  <Button label="Add Signature" onClick={() => setOpen(true)} />
                </div>
                <SettingItem
                  label="Common Signatures"
                  value={printSetting?.commonsigns || false}
                  handleChange={(value) =>
                    setPrintSetting({
                      ...printSetting,
                      commonsigns: value,
                      departmentwisesigns: !value,
                    })
                  }
                  className="font-semibold"
                />
                <div className="flex gap-4 p-2">
                  {printSetting.signs?.map((sign) => (
                    <div className="flex flex-col relative">
                      <img
                        src={`${url}/uploadedFiles/${sign.signature}`}
                        alt=""
                        className="w-40 my-2 shadow-md border border-black/10"
                      />
                      <div
                        onClick={() => handleDeleteSign(sign.id)}
                        className="absolute right-1 top-3 p-1 rounded-full cursor-pointer text-red-500 bg-white hover:bg-red-500 hover:text-white"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </div>
                      <p className="text-sm text-gray-700">{sign.doctorname}</p>
                      <p className="text-xs text-gray-500">
                        {sign.specialisation}
                      </p>
                    </div>
                  ))}
                </div>
                <SettingItem
                  label="Department Order"
                  value={printSetting?.departmentwise || false}
                  handleChange={(value) =>
                    setPrintSetting({ ...printSetting, departmentwise: value })
                  }
                  className="font-semibold"
                />

                {departments.map((department, index) => (
                  <div
                    key={department.id}
                    draggable
                    onDragStart={() => (dragPerson.current = index)}
                    onDragEnter={() => (draggedOverPerson.current = index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                    className="cursor-move bg-gray-200 p-2 flex justify-between items-center"
                  >
                    <p>{department.name}</p>
                    <div className="flex">
                      <ArrowUpIcon className="h-5 w-5" />
                      <ArrowDownIcon className="h-5 w-5" />
                    </div>
                  </div>
                ))}
                <Button
                  label="Save"
                  onClick={() => handleSave()}
                  loading={loading}
                  className="self-end mt-4"
                />
              </div>
            </div>
            <div className=" py-2 px-8 rounded-md h-full w-full bg-white shadow-md my-3">
              <p className="text-lg m-2 font-bold">Advance Setting</p>
            </div>
            <div className="p-3 px-7 rounded-md h-full w-full bg-white shadow-md my-3">
              <p className="text-md font-semibold text-gray-600">
                Whatsapp Setting
              </p>
              <FormSelect
                value={printSetting?.whatsappSetting || ""}
                setValue={(value) => {
                  setPrintSetting({
                    ...printSetting,
                    whatsappSetting: value as string,
                  });
                  handlesave2(value);
                }}
                placeholder="Select Whatsapp Setting"
                options={[
                  { value: "manual", label: "Manual" },
                  { value: "automatic", label: "Automatic" },
                  { value: "disabled", label: "Disabled" },
                ]}
                inputClassName="!border-0 !border-b  rounded-b-none h-9 focus-within:border-b-2"
                divClassName="border-0 !m-0 py-2 w-fit"
              />
            </div>
          </div>
        )
      )}
      <DialogBox open={open} setOpen={setOpen}>
        <div className="p-4">
          <p className="text-md font-semibold">Add Signature</p>
          <Divider className="my-2 border !border-t-gray-400" />
          <SignsUpload
            id={printSetting.id}
            handleClose={() => setOpen(false)}
          />
          {/* <div className="flex flex-col gap-4 mt-4">
            <div className="flex gap-4 items-center">
              <SettingItem
                label="Department Wise Signatures"
                value={printSetting?.departmentwisesigns || false}
                handleChange={(value) =>
                  setPrintSetting({
                    ...printSetting,
                    departmentwisesigns: value,
                  })
                }
                className="font-semibold"
              />
              <p className="text-sm font-semibold">OR</p>
              <Button label="Add Signature" onClick={() => setOpen(true)} />
            </div>
          </div> */}
        </div>
      </DialogBox>
    </div>
  );
}

interface SettingItemProps {
  label: string;
  value: boolean;
  handleChange: (value: boolean) => void;
  className?: string;
}

const SettingItem = ({
  label,
  value,
  handleChange,
  className,
}: SettingItemProps) => {
  return (
    <div className={cn("flex gap-2 ", className)}>
      <input
        className="w-4 h-4 transition-all duration-300 ease-in-out rounded-sm border-gray-500 outline-none cursor-pointer"
        type="checkbox"
        id={label}
        checked={value}
        onChange={(e) => {
          handleChange(e.target.checked);
        }}
      />
      <label htmlFor={label} className="text-sm cursor-pointer">
        {label}
      </label>
    </div>
  );
};
