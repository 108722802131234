import React from "react";

interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  label?: string;
  placeholder: string;
  options: { value: string | number; label: string }[];
  divClassName?: string;
  className?: string;
}

export default function Select({
  label,
  placeholder,
  divClassName,
  options,
  className,
  ...rest
}: Props) {
  return (
    <div className={`flex flex-col  ${divClassName}`}>
      {label && (
        <label className="text-sm font-bold text-gray-700 mx-2">{label}</label>
      )}
      <select
        className={`relative  text-sm text-gray-700 pr-2 flex items-center w-full  rounded-sm focus-within:shadow-lg border-2 border-gray-500 focus-within:border-gray-500 bg-white overflow-hidden ml-[10px] px-2 h-9`}
        {...rest}
      >
        <option value="" selected>
          {placeholder}
        </option>
        {options.map((option) => (
          <option className="text-gray-600" value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
