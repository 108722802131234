import axios from "axios";

export const url = "https://server.labauto.in";

export const instanceid = "6697B2AE0F9A4";

export const accessToken = "66979e95d92b0";

export const whatsappurl = "https://whatsapp.labauto.in/api";

//export const url = "http://localhost:9087";

export const api = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getDepartments = async () => {
  try {
    const response = await api.get("/department/getDepartments");
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteFile = async (filename: string) => {
  try {
    const res = await api.delete("deleteFile/" + filename);
    console.log(res.data);
  } catch (error) {
    console.error(error);
  }
};

export const getDepartment = async (id: number | string) => {
  try {
    const response = await api.get(`/department/getDepartment/${id}`);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getTests = async () => {
  try {
    const response = await api.get("/test/getTests");
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getTest = async (id: number | string) => {
  try {
    const response = await api.get(`/test/getTest/${id}`);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getProfiles = async () => {
  try {
    const response = await api.get("/profile/getProfiles");
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getProfile = async (id: number | string) => {
  try {
    const response = await api.get(`/profile/getProfile/${id}`);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return { profile: undefined, tests: [], references: [] };
  }
};

export const getPackages = async () => {
  try {
    const response = await api.get("/package/getPackages");
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getPackage = async (id: number | string) => {
  try {
    const response = await api.get(`/package/getPackage/${id}`);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getPriceLists = async (type: string) => {
  try {
    const response = await api.get("/pricelist/getPricelist?type=" + type);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return { tests: [], headers: [] };
  }
};

export const getRefLabs = async () => {
  try {
    const branch = JSON.parse(localStorage.getItem("branch") || "{}");
    const response = await api.get("/reflab/getRefLabs?branchIds=" + branch);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getRefLab = async (id: number | string) => {
  try {
    const response = await api.get(`/reflab/getRefLab/${id}`);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getRefDoctors = async () => {
  try {
    const branch = JSON.parse(localStorage.getItem("branch") || "{}");
    const response = await api.get(
      "/refdoctor/getRefDoctors?branchIds=" + branch
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getBranches = async (userId?: number) => {
  try {
    const response = await api.get("/branch/getBranches?userId=" + userId);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getBranch = async (id: number | string) => {
  try {
    const response = await api.get(`/branch/getBranch/${id}`);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
