interface AppWidgetSummaryProps {
  title: string;
  total: number;
  icon?: React.ReactNode;
  color?: "primary" | "secondary";
}

const AppWidgetSummary = ({
  title,
  total,
  icon,
  color = "primary",
  ...other
}: AppWidgetSummaryProps) => {
  return (
    <div
      className={`p-6 bg-white flex gap-6 rounded-lg shadow-sm border border-stroke`}
      {...other}
    >
      {icon && <div className="w-16 h-16">{icon}</div>}

      <div className="flex flex-col space-y-1">
        <h4 className="text-2xl font-semibold">{total}</h4>

        <p className="text-sm text-gray-500">{title}</p>
      </div>
    </div>
  );
};

export default AppWidgetSummary;

// Add Tailwind CSS classes as needed for styling
