import axios from "axios";
import React, { useEffect } from "react";
import { api } from "../../Api";

export default function Users() {
  const [users, setUsers] = React.useState<User[]>([]);
  const [branches, setBranches] = React.useState<any[]>([]);

  const fetchUsers = async () => {
    const res = await api.get("/user/getUsers");
    setUsers(res.data.data || []);
  };

  useEffect(() => {
    setBranches(JSON.parse(localStorage.getItem("branch") || "[]"));

    fetchUsers();
  }, []);

  // const branches = []
  //   localStorage
  //     .getItem("branch")
  //     ?.split(",")
  //     .map((b) => parseInt(b)) || [];

  console.log(branches);

  return (
    <div className="flex flex-col">
      <p className="text-right mx-5 my-5 font-medium text-sm">
        Administration {">"} User Creation
      </p>
      <div className="bg-white mx-3 p-6 rounded-md ">
        <div className="flex justify-between mb-3">
          {/* <div className="max-w-md mx-auto"> */}

          <div className="relative max-w-sm flex items-center w-full h-12 rounded-lg focus-within:shadow-lg border-2 border-gray-400 focus-within:border-gray-500 bg-white overflow-hidden ml-[10px]">
            <div className="grid place-items-center h-full w-12 text-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>

            <input
              className="peer h-full w-full outline-none text-sm text-gray-700 pr-2 border-gray-700"
              type="text"
              id="search"
              placeholder="Search something.."
            />
          </div>
          {/* </div> */}
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 text-sm rounded">
            <a href="/user/add">Add User</a>
          </button>
        </div>
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr className="bg-[#e0e0e0] py-3 h-[4rem]">
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-700 uppercase "
                    >
                      S No
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-700 uppercase "
                    >
                      User Id
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-700 uppercase "
                    >
                      User Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-700 uppercase "
                    >
                      Phone Number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-700 uppercase "
                    >
                      Password
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-700 uppercase "
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-700 uppercase "
                    >
                      Branches
                    </th>
                    <th
                      scope="col"
                      colSpan={2}
                      align="center"
                      className="px-6 py-3 text-xs font-bold text-center text-gray-700 uppercase "
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {users
                    .filter(
                      (u) =>
                        u.allbranches ||
                        u.branches.find((u) => branches.includes(u.id))
                    )
                    .map((user: User, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {user.id}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {user.username}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {user.phonenumber}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {user.password}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {user.rolename}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {user.allbranches
                            ? "All Branches"
                            : user.branches &&
                              user.branches
                                .map((branch: any) => branch.name)
                                .join(", ")}
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-center whitespace-nowrap">
                          <a
                            className="text-green-500 hover:text-green-700"
                            href={`/user/${user.id}`}
                          >
                            Edit
                          </a>
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-center whitespace-nowrap">
                          <a
                            className="text-red-500 hover:text-red-700"
                            href="#"
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
